import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type id } from '@cca-infra/common';
import {
  type NotificationPreferencesGetResponse,
  type NotificationPreferencesUpdateRequest,
} from '../old/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseApiService {
  constructor() {
    super(`notification.v1.notification`);
  }

  countUnread(userId: id) {
    return this.http.get<number>(`${this.url}count-unread/${userId}`);
  }

  getNotificationPreferences(userId: id) {
    return this.http.get<NotificationPreferencesGetResponse>(
      `${this.url}preferences/${userId}`,
    );
  }

  updateNotificationPreferences(
    userId: id,
    userPreferences: NotificationPreferencesUpdateRequest[],
  ) {
    return this.http.put(`${this.url}update-preferences`, {
      userId,
      userPreferences,
    });
  }

  getUserGroupNotificationPreferences(userGroupId: id) {
    return this.http.get<NotificationPreferencesGetResponse>(
      `${this.url}preferences/usergroup/${userGroupId}`,
    );
  }

  updateUserGroupNotificationPreferences(
    userGroupId: id,
    userGroupPreferences: NotificationPreferencesUpdateRequest[],
  ) {
    return this.http.put(`${this.url}update-usergroup-preferences`, {
      userGroupId,
      userGroupPreferences,
    });
  }
}
