import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type Observable } from 'rxjs';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import { type WebNotificationViewModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class WebNotificationService extends BaseApiService {
  constructor() {
    super(`notification.v1.webNotification`);
  }

  notificationPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<WebNotificationViewModel>> {
    return this.http.post<PaginationResponse<WebNotificationViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  markAsRead(notificationId: id) {
    return this.http.post<number>(`${this.url}mark-as-read`, {
      notificationId: notificationId,
    });
  }

  markAllAsRead(userId: id) {
    return this.http.post(`${this.url}mark-all-as-read`, {
      userId: userId,
    });
  }

  markAsUnread(notificationId: id) {
    return this.http.post<number>(`${this.url}mark-as-unread`, {
      notificationId: notificationId,
    });
  }

  countUnread(userId: id) {
    return this.http.get<number>(`${this.url}count-unread/${userId}`);
  }
}
