import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type EmailDownloadViewModel, type EmailViewModel } from '../model';
import { type EntityType, type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseApiService {
  constructor() {
    super(`notification.v1.email`);
  }

  getEmails(entityId: id, entityType: EntityType) {
    return this.http.post<EmailViewModel[]>(`${this.url}get-emails`, {
      entityId: entityId,
      entityType: entityType,
    });
  }

  downloadEmail(entityId: id, entityType: EntityType, emailId: id) {
    return this.http.post<EmailDownloadViewModel>(`${this.url}download-email`, {
      emailId: emailId,
      entityId: entityId,
      entityType: entityType,
    });
  }
}
