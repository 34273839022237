/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Sub type of the web push message  Can be used to map a link in the front-end or render a different icon  0 = UserMentioned  1 = TaskCreationRequest  2 = TenderRelated  3 = SystemAutomationSuccess  4 = SystemAutomationFailed  5 = TaskManuallyClosed  6 = OrderRelated  7 = LanesRequestRelated  8 = DownloadReady
 */
export const WebPushMessageSubType = {
  UserMentioned: 0,
  TaskCreationRequest: 1,
  TenderRelated: 2,
  SystemAutomationSuccess: 3,
  SystemAutomationFailed: 4,
  TaskManuallyClosed: 5,
  OrderRelated: 6,
  LanesRequestRelated: 7,
  DownloadReady: 8,
} as const;

export type WebPushMessageSubType =
  (typeof WebPushMessageSubType)[keyof typeof WebPushMessageSubType];

/**
 * @deprecated use EnumViewModel<WebPushMessageSubType> instead.
 *
 * Maps a WebPushMessageSubType enumeration value to its corresponding translation key.
 *
 * @param { WebPushMessageSubType } webPushMessageSubType -
 *        The enumeration value representing the type of WebPushMessageSubType.
 * @returns {string} The translation key corresponding to the given WebPushMessageSubType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('WebPushMessageSubType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromWebPushMessageSubType(WebPushMessageSubType.TaskCreationRequest);
 * console.log(translationKey); // 'WebPushMessageSubType.TaskCreationRequest'
 * console.log(translocoService.translate(translationKey)) // "Translation of WebPushMessageSubType.TaskCreationRequest"
 *
 * // using in a template:
 * {{ translationKeyFromWebPushMessageSubType(WebPushMessageSubType.TaskCreationRequest) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('WebPushMessageSubType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromWebPushMessageSubType(
  webPushMessageSubType: WebPushMessageSubType,
) {
  switch (webPushMessageSubType) {
    case WebPushMessageSubType.UserMentioned:
      return 'WebPushMessageSubType.UserMentioned';
    case WebPushMessageSubType.TaskCreationRequest:
      return 'WebPushMessageSubType.TaskCreationRequest';
    case WebPushMessageSubType.TenderRelated:
      return 'WebPushMessageSubType.TenderRelated';
    case WebPushMessageSubType.SystemAutomationSuccess:
      return 'WebPushMessageSubType.SystemAutomationSuccess';
    case WebPushMessageSubType.SystemAutomationFailed:
      return 'WebPushMessageSubType.SystemAutomationFailed';
    case WebPushMessageSubType.TaskManuallyClosed:
      return 'WebPushMessageSubType.TaskManuallyClosed';
    case WebPushMessageSubType.OrderRelated:
      return 'WebPushMessageSubType.OrderRelated';
    case WebPushMessageSubType.LanesRequestRelated:
      return 'WebPushMessageSubType.LanesRequestRelated';
    case WebPushMessageSubType.DownloadReady:
      return 'WebPushMessageSubType.DownloadReady';
    default:
      return '';
  }
}
